(function() {

    //Find element with id datepicker, initialize it and validate on change
    var datepicker = $('#datepicker').datepicker({
        startDate: "today",
        autoclose: true,
        todayHighlight: true
    }).on('changeDate', function(e) {
        var inputParsley = $('#datepicker').parsley();
        inputParsley.validate();
    });

    //When user finishes input of the url suffix set the
    //hidden field value to full url and validate it.
    $('#evaluation_link').on('blur', function() {
        var fullUrlField = $('#evaluation_link_full');

        var fullUrl = '';
        if($(this).val()){
            fullUrl = 'https://wisergiving.org/e/' + $(this).val();
        }

        fullUrlField.val(fullUrl);

        var inputParsley = fullUrlField.parsley();
        inputParsley.validate();

        if(!inputParsley.isValid()){
            $(this).addClass('parsley-error');
            $(this).removeClass('parsley-success');
        } else {
            $(this).addClass('parsley-success');
            $(this).removeClass('parsley-error');
        }
    });

    //When user finishes input of the url suffix set the
    //hidden field value to full url and validate it.
    $('#survey_link').on('blur', function() {
        var fullUrlField = $('#survey_link_full');

        var fullUrl = '';
        if($(this).val()){
            fullUrl = 'https://wisergiving.org/q/' + $(this).val();
        }

        fullUrlField.val(fullUrl);

        var inputParsley = fullUrlField.parsley();
        inputParsley.validate();

        if(!inputParsley.isValid()){
            $(this).addClass('parsley-error');
            $(this).removeClass('parsley-success');
        } else {
            $(this).addClass('parsley-success');
            $(this).removeClass('parsley-error');
        }
    });

    //initialize jquery select2 workshop admin select box
    $('#workshop_admins').select2({
        placeholder: "Add additional Workshop Admins"
    });

    //initialize jquery select2 trainers select box
    $('#trainers').select2({
        placeholder: "Select trainers"
    });

    //initialize popovers
    $('[data-toggle="popover"]').popover();

})();