(function () {

    // Find any element that has a custom attribute data-click-submits-form-confirm
    // and on click open the confirm delete modal if exists or immediately
    // find the closest form and submit it, if it modal doesn't exist.
    $('*[data-click-submits-form-confirm]').on('click', function() {
        var form = $(this).closest('form');
        var deleteModal = $('*[data-delete-modal]');

        if(deleteModal.length){
            deleteModal.modal({ backdrop: 'static', keyboard: false })
                .one('click', '#delete', function (e) {
                    form.submit();
                });
        } else {
            form.submit();
        }
    });

    // Find any element that has a custom attribute data-click-submits-form
    // and then find the closest form and submit it.
    $('*[data-click-submits-form]').on('click', function() {
        $('.spinner-modal').fadeIn(500);
        var form = $(this).closest('form');
        form.submit();
    });

})();

